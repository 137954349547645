import React, { useEffect, useState } from "react";
import * as A from '../../../assets/images';
import * as P from './parts';
import { Trans, useTranslation } from "react-i18next";
import { Container } from "styled-bootstrap-grid";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import Cookies from "js-cookie";
import Fade from 'react-reveal';
import axios from "axios";
import {CSSTransition} from "react-transition-group";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const discoveryMethods = ['demo_page_select_option_social_media', 'demo_page_select_option_search_engine', 'demo_page_select_option_rating_and_review_websites', 'demo_page_select_option_others'];

const SectionDemo = ({ handleOpenPopup }) => {

  const {t} = useTranslation();

  const demoFormSchema = Yup.object().shape({
    name: Yup.string().max(30, t('demo_page_error_length_name')).required(t('demo_page_error_field_name_is_required')),
    companyName: Yup.string().max(100, t('demo_page_error_length_company_name')).required(t('demo_page_error_field_is_company_name_required')),
    email: Yup.string().email(t('demo_page_error_field_email_is_not_valid')).max(50, t('demo_page_error_length_email')).required(t('demo_page_error_field_email_is_required')),
    countryCode: Yup.string().matches(/^\d+$/, t('demo_page_error_field_country_code_is_not_valid')).max(5, t('demo_page_error_length_country_code')).required(t('demo_page_error_field_country_code_is_required')),
    phoneNumber: Yup.string().matches(phoneRegExp, t('demo_page_error_field_phone_number_is_not_valid')).max(10, t('demo_page_error_length_phone_number')).required(t('demo_page_error_field_phone_number_is_required')),
    agreementCheckbox: Yup.boolean().oneOf([true], t('demo_page_error_field_agreement_checkbox_is_required')),
    discoveryMethods: Yup.string().required(t('demo_page_error_field_discovery_methods_is_required')),
    otherDiscoveryMethodDescription: Yup.string().max(150, t('demo_page_error_length_other_discovery_method_description')).when(
        "discoveryMethods", {
          is: t(discoveryMethods[3]),
          then: Yup.string().required(t('demo_page_error_field_other_discovery_method_description'))
        }
    )
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      companyName: "",
      email: "",
      countryCode: "",
      phoneNumber: "",
      discoveryMethods: t(discoveryMethods[0]),
      agreementCheckbox: false,
      otherDiscoveryMethodDescription: ""
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: demoFormSchema,
    onSubmit: () => {
      handleSubmitForm();
    }
  });

  const [isFetching, setIsFetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  function handleSubmitForm() {
    handleOpenPopup();
    Cookies.set("modalViewed", "true", { expires: 14 });
    setIsFetching(true);
    axios({
      method: 'post',
      url: `https://${process.env.GATSBY_API_URL}/forms/v1/order-mobd`,
      responseType: 'json',
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
      data: {
        email: formik.values.email,
        companyName: formik.values.companyName,
        countryCode: formik.values.countryCode,
        discoveryMethods: formik.values.discoveryMethods,
        name: formik.values.name,
        otherDiscoveryMethodDescription: formik.values.otherDiscoveryMethodDescription || "-",
        phoneNumber: formik.values.phoneNumber
      }
    })
      .then(() => {
        setIsFetching(false);
        setSuccess(true);
        formik.resetForm();
      })
      .catch(() => {
        setIsFetching(false);
        setError(true);
    })
  }

  const handleChange = (e, name) => {
    formik.handleChange(e);
    formik.setFieldTouched(name)
  }

  useEffect(() => {
    if(success) {
      const successTimer = setTimeout(() => setSuccess(false), 5000);
      return () => clearTimeout(successTimer);
    }
  }, [success])

  useEffect(() => {
    if(error) {
      const errorTimer = setTimeout(() => setError(false), 5000);
      return () => clearTimeout(errorTimer);
    }
  }, [error])

  return (
    <P.StyledSection>
      <Container>
        <P.StyledTitle>
          <Trans>demo_page_interested_in_mobd</Trans>
        </P.StyledTitle>
        <FormikProvider value={formik}>
          <P.StyledForm onSubmit={formik.handleSubmit}>
            <P.FormTopSide>
              <Fade>
                <P.FormContent>
                  <P.StyledText>
                    <Trans>demo_page_see_mobd_in_action</Trans>
                  </P.StyledText>
                  <P.StyledText>
                    <Trans>demo_page_manage_your_fleet</Trans>
                  </P.StyledText>
                  <P.ImgWrapper>
                    <P.StyledImg src={A.adminPanelDemo} alt={"Admin panel"} />
                  </P.ImgWrapper>
                </P.FormContent>
              </Fade>
              <Fade>
                <P.StyledFieldsWrapper>
                  <P.InputWrapper>
                    <P.StyledLabel htmlFor={"name"}>
                      <Trans>demo_page_full_name</Trans>*
                    </P.StyledLabel>
                    <P.StyledInput
                      id={"name"}
                      name={"name"}
                      type={"text"}
                      value={formik.values.name}
                      onChange={(e) => handleChange(e, 'name')}
                    />
                    {formik.errors.name && formik.touched.name ?
                      <P.StyledError>{formik.errors.name}</P.StyledError> : null}
                  </P.InputWrapper>
                  <P.InputWrapper>
                    <P.StyledLabel htmlFor={"companyName"}>
                      <Trans>demo_page_company_name</Trans>*
                    </P.StyledLabel>
                    <P.StyledInput
                      id={"companyName"}
                      name={"companyName"}
                      type={"text"}
                      value={formik.values.companyName}
                      onChange={(e) => handleChange(e, 'companyName')}
                    />
                    {formik.errors.companyName && formik.touched.companyName ?
                      <P.StyledError>{formik.errors.companyName}</P.StyledError> : null}
                  </P.InputWrapper>
                  <P.InputWrapper>
                    <P.StyledLabel htmlFor={"email"}>
                      <Trans>demo_page_business_email_address</Trans>*
                    </P.StyledLabel>
                    <P.StyledInput
                      id={"email"}
                      name={"email"}
                      type={"email"}
                      value={formik.values.email}
                      onChange={(e) => handleChange(e, 'email')}
                    />
                    {formik.errors.email && formik.touched.email ?
                      <P.StyledError>{formik.errors.email}</P.StyledError> : null}
                  </P.InputWrapper>
                  <P.InputRow>
                    <P.SmallInputWrapper maxWidth={"150px"}>
                      <P.StyledLabel htmlFor={"countryCode"}>
                        <Trans>demo_page_country_code</Trans>*
                      </P.StyledLabel>
                      <P.StyledInput
                        id={"countryCode"}
                        name={"countryCode"}
                        type={"string"}
                        value={formik.values.countryCode}
                        onChange={(e) => handleChange(e, 'countryCode')}
                      />
                      {formik.errors.countryCode && formik.touched.countryCode ?
                        <P.StyledError>{formik.errors.countryCode}</P.StyledError> : null}
                    </P.SmallInputWrapper>
                    <P.SmallInputWrapper maxWidth={"255px"} minWidth={"155px"} margin={"0px 0px 0px 10px"}>
                      <P.StyledLabel htmlFor={"phoneNumber"}>
                        <Trans>demo_page_phone_number</Trans>*
                      </P.StyledLabel>
                      <P.StyledInput
                        id={"phoneNumber"}
                        name={"phoneNumber"}
                        type={"string"}
                        value={formik.values.phoneNumber}
                        onChange={(e) => handleChange(e, 'phoneNumber')}
                      />
                      {formik.errors.phoneNumber && formik.touched.phoneNumber ?
                        <P.StyledError textAlign={"right"}>{formik.errors.phoneNumber}</P.StyledError> : null}
                    </P.SmallInputWrapper>
                  </P.InputRow>
                  <P.InputWrapper>
                    <P.RadioTitle>
                      <Trans>demo_page_how_did_you_discovered</Trans>
                      <P.RadioTitleBold>
                        <Trans>demo_page_mobd</Trans>
                      </P.RadioTitleBold>?*
                    </P.RadioTitle>
                    <P.RadioContainer>
                      {
                        discoveryMethods.map((item, index) => {
                          return (
                            <P.RadioItem key={`${item}_${index}`}>
                              <P.StyledRadioLabel htmlFor={`discoveryMethods_${item}`}>
                                <P.StyledRadioInput
                                  value={t(item)}
                                  name={"discoveryMethods"}
                                  type={"radio"}
                                  id={`discoveryMethods_${item}`}
                                  checked={formik.values.discoveryMethods === t(item)}
                                  onChange={(e) => handleChange(e, 'discoveryMethods')}
                                />
                                <P.CustomRadioDot></P.CustomRadioDot>
                                {t(item)}
                              </P.StyledRadioLabel>
                            </P.RadioItem>
                          );
                        })
                      }
                      {formik.values.discoveryMethods === t(discoveryMethods[3]) &&
                        <P.OptionalInputWrapper>
                          <P.OptionalInput
                            placeholder={t("demo_page_select_option_please_specify")}
                            value={formik.values.otherDiscoveryMethodDescription}
                            onChange={(e) => handleChange(e, 'otherDiscoveryMethodDescription')}
                            id={"otherDiscoveryMethodDescription"}
                            name={"otherDiscoveryMethodDescription"}
                          />
                          {formik.errors.otherDiscoveryMethodDescription && formik.touched.otherDiscoveryMethodDescription ?
                            <P.StyledError>{formik.errors.otherDiscoveryMethodDescription}</P.StyledError> : null}
                        </P.OptionalInputWrapper>
                      }
                    </P.RadioContainer>
                  </P.InputWrapper>
                  <P.RequiredFieldsText>*
                    <Trans>demo_page_required_fields</Trans>
                  </P.RequiredFieldsText>
                </P.StyledFieldsWrapper>
              </Fade>
            </P.FormTopSide>
            <P.FormBottomSide>
              <Fade>
                <P.CheckboxWrapper>
                  <P.StyledCheckboxLabel htmlFor={"agreementCheckbox"}>
                    <P.StyledCheckbox
                      type={"checkbox"}
                      id={"agreementCheckbox"}
                      name={"agreementCheckbox"}
                      checked={formik.values.agreementCheckbox}
                      onChange={(e) => handleChange(e, 'agreementCheckbox')}
                    />
                    <P.CustomCheckboxMark></P.CustomCheckboxMark>
                    <P.EmptyDiv></P.EmptyDiv>
                    <Trans>demo_page_privacy_policy_your_information</Trans>
                    <P.PolicyLink to={"/privacy/"}>
                      <Trans>demo_page_privacy_policy_link</Trans>
                    </P.PolicyLink>
                    <Trans>demo_page_privacy_policy_you_can</Trans>
                    <P.PolicyLink to={"/terms/"}>
                      <Trans>demo_page_privacy_policy_withdraw</Trans>
                    </P.PolicyLink>
                    <Trans>demo_age_privacy_policy_at_any_time</Trans>
                  </P.StyledCheckboxLabel>
                  {formik.errors.agreementCheckbox && formik.touched.agreementCheckbox ?
                    <P.StyledError>{formik.errors.agreementCheckbox}</P.StyledError> : null}
                </P.CheckboxWrapper>
              </Fade>
            </P.FormBottomSide>
            <Fade>
              <P.SubmitButton
                type={"submit"}
                disabled={ isFetching || success || error }
              >
                { isFetching ?
                  <P.StyledLoader /> :
                  success ?
                    <>
                      <Trans>demo_page_form_sent</Trans>
                    </> :
                    error ?
                      <>
                        <Trans>something_went_wrong</Trans>&nbsp;
                        <Trans>please_try_again_later</Trans>
                      </> :
                      <Trans>demo_page_request_a_demo_btn</Trans>
                }
              </P.SubmitButton>
              <CSSTransition
                in={success}
                timeout={200}
                classNames='mobile__menu-list'
                unmountOnExit
              >
                <P.FormSuccessContainer>
                  <P.FormSuccessMessage>
                    <Trans>demo_page_form_we_are_so_happy</Trans>
                  </P.FormSuccessMessage>
                  <P.FormSuccessMessage>
                    <Trans>demo_page_form_our_demo_link</Trans>
                  </P.FormSuccessMessage>
                </P.FormSuccessContainer>
              </CSSTransition>
            </Fade>
          </P.StyledForm>
        </FormikProvider>
        <Fade>
          <P.MoreInfo>
            <P.MoreInfoText>
              <Trans>demo_page_more_info</Trans>
            </P.MoreInfoText>
            <P.MoreInfoArrow />
          </P.MoreInfo>
        </Fade>
      </Container>
    </P.StyledSection>
  );
};

export default SectionDemo;