import React, { useEffect, useState } from "react";
import * as P from './parts';
import * as A from '../../../assets/images';
import { Trans, useTranslation } from "react-i18next";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import { Container } from "styled-bootstrap-grid";
import Fade from "react-reveal";
import axios from "axios";

const SectionSupport = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const {t} = useTranslation();

  const supportFormSchema = Yup.object().shape({
    name: Yup.string().max(30, t('support_page_error_length_name')).required(t('support_page_error_field_name_is_required')),
    email: Yup.string().email(t('support_page_error_email_is_invalid')).max(50, t('support_page_error_length_email')).required(t('support_page_error_field_email_is_required')),
    message: Yup.string().max(5000, t('support_page_error_length_message')).required(t('support_page_error_field_message_is_required')),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: supportFormSchema,
    onSubmit: () => {
      handleSubmitForm();
    },
  })

  function handleSubmitForm () {
    setIsFetching(true);
    axios({
      method: 'post',
      url: `https://${process.env.GATSBY_API_URL}/forms/v1/support-request`,
      responseType: 'json',
      crossDomain: true,
      headers: {
        'Content-Type': 'application/json',
        Accept: '*/*',
      },
      data: formik.values,
    })
      .then(() => {
        setIsFetching(false);
        setSuccess(true);
        formik.resetForm();
      })
      .catch(() => {
        setIsFetching(false);
        setError(true);
      })
  }

  const handleChange = (e, name) => {
    formik.handleChange(e);
    formik.setFieldTouched(name)
  }

  useEffect(() => {
    if(success) {
      const successTimer = setTimeout(() => setSuccess(false), 5000);
      return () => clearTimeout(successTimer);
    }
  }, [success])

  useEffect(() => {
    if(error) {
      const errorTimer = setTimeout(() => setError(false), 5000);
      return () => clearTimeout(errorTimer);
    }
  }, [error])

  return (
    <P.StyledSection>
      <Container>
        <FormikProvider value={formik}>
          <P.StyledForm onSubmit={formik.handleSubmit}>
            <Fade>
              <P.StyledTitle>
                <Trans>support_page_welcome_support</Trans>
              </P.StyledTitle>
            </Fade>
            <P.FormWrapper>
              <Fade>
                <P.FormContent>
                  <P.FormSubtitle>
                    <P.StyledText>
                      <Trans>support_page_contact_us_directly</Trans>
                      <P.OrangeLink href={'tel:+48536008632'}>+48 536 008 632</P.OrangeLink>
                      <Trans>support_page_and</Trans>
                      <P.OrangeLink href={'mailto:sales@mobd.pl'}>sales@mobd.pl</P.OrangeLink>
                      <Trans>support_page_or_use_contact_form</Trans>
                    </P.StyledText>
                  </P.FormSubtitle>
                  <P.ImgWrapper>
                    <P.StyledImg src={A.macbookpro2} alt={"Admin panel"} />
                  </P.ImgWrapper>
                </P.FormContent>
              </Fade>
              <Fade>
                <P.StyledFieldsWrapper>
                  <P.InputWrapper>
                    <P.StyledLabel htmlFor={"name"}>
                      <Trans>support_page_full_name</Trans>*
                    </P.StyledLabel>
                    <P.StyledInput
                      id={"name"}
                      name={"name"}
                      type={"text"}
                      value={formik.values.name}
                      onChange={(e) => handleChange(e, "name")}
                    />
                    {formik.errors.name && formik.touched.name ?
                      <P.StyledError>{formik.errors.name}</P.StyledError> : null}
                  </P.InputWrapper>
                  <P.InputWrapper>
                    <P.StyledLabel htmlFor={"email"}>
                      <Trans>support_page_business_email_address</Trans>*
                    </P.StyledLabel>
                    <P.StyledInput
                      id={"email"}
                      name={"email"}
                      type={"email"}
                      value={formik.values.email}
                      onChange={(e) => handleChange(e, "email")}
                    />
                    {formik.errors.email && formik.touched.email ?
                      <P.StyledError>{formik.errors.email}</P.StyledError> : null}
                  </P.InputWrapper>
                  <P.InputWrapper>
                    <P.StyledLabel htmlFor={"message"}>
                      <Trans>support_page_your_message</Trans>*
                    </P.StyledLabel>
                    <P.StyledTextarea
                      as={'textarea'}
                      id={"message"}
                      name={"message"}
                      type={"text"}
                      value={formik.values.message}
                      onChange={(e) => handleChange(e, "message")}
                    />
                    {formik.errors.message && formik.touched.message ?
                      <P.StyledError>{formik.errors.message}</P.StyledError> : null}
                  </P.InputWrapper>
                  <P.RequiredFieldsText>*
                    <Trans>support_page_required_fields</Trans>
                  </P.RequiredFieldsText>
                  <Fade>
                    <P.SubmitButton
                      type={'submit'}
                      disabled={ isFetching || success || error }
                    >
                      { isFetching ?
                        <P.StyledLoader /> :
                        success ?
                          <>
                            <Trans>support_page_form_sent</Trans>
                          </> :
                          error ?
                            <>
                              <Trans>support_page_something_went_wrong</Trans>
                            </> :
                            <Trans>support_page_send_button</Trans>
                      }
                    </P.SubmitButton>
                  </Fade>
                </P.StyledFieldsWrapper>
              </Fade>
            </P.FormWrapper>
          </P.StyledForm>
        </FormikProvider>
      </Container>
    </P.StyledSection>
  );
}

export default SectionSupport;